import { createRouter, createWebHashHistory } from 'vue-router'
import { isEmpty } from 'lodash'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/layout/layout.vue'),
    redirect: '/home/device',
    children: [
      {
        path: '/home/device',
        name: 'home',
        component: () => import('@/pages/home/home.vue'),
        meta: {
          title: '驾驶舱'
        },
      },
      {
        path: '/home/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/home/dashboard.vue'),
        meta: {
          title: '仪表盘'
        },
      },
      {
        path: '/home/dashboard/:id',
        name: 'dashboardId',
        component: () => import('@/pages/home/dashboardId.vue'),
        meta: {
          title: '仪表盘'
        },
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/pages/purchase/purchase.vue'),
        meta: {
          title: '采购管理'
        },
        redirect: '/purchase/purchase-list',
        children: [
          {
            path: '/purchase/purchase-list',
            name: 'purchase-list',
            component: () => import('@/pages/purchase/components/purchase-list.vue'),
            meta: {
              title: '采购列表'
            }
          },
          {
            path: '/purchase/purchase-list/:id',
            name: 'purchase-details',
            component: () => import('@/pages/purchase/components/purchase-details.vue'),
            meta: {
              title: '采购详情'
            }
          },
        ]
      },
      {
        path: '/sell',
        name: 'sell',
        component: () => import('@/pages/sell/sell.vue'),
        meta: {
          title: '销售管理'
        },
        redirect: '/sell/sell-list',
        children: [
          {
            path: '/sell/sell-list',
            name: 'sell-list',
            component: () => import('@/pages/sell/components/sell-list.vue'),
            meta: {
              title: '销售列表'
            }
          },
          {
            path: '/sell/sell-list/:id',
            name: 'sell-details',
            component: () => import('@/pages/sell/components/sell-details.vue'),
            meta: {
              title: '销售详情'
            }
          },
        ]
      },
      {
        path: '/storage',
        name: 'storage',
        component: () => import('@/pages/storage/storage.vue'),
        meta: {
          title: '仓储管理'
        }
      },
      {
        name: 'basic',
        path: '/basic',
        component: () => import('@/pages/basic/basic.vue'),
        meta: {
          title: '基础信息管理'
        }
      },
      {
        name: 'statistics',
        path: '/statistics',
        component: () => import('@/pages/statistics/statistics.vue'),
        meta: {
          title: '数据统计'
        }
      },
      {
        path: '/setting',
        name: 'setting',
        redirect: '/setting/users',
        meta: {
          title: '系统设置'
        },
        children: [
          {
            path: '/setting/users',
            name: 'users',
            component: () => import('@/pages/setting/users.vue'),
            meta: {
              title: '用户管理'
            }
          },
          {
            path: '/setting/corporation',
            name: 'corporation',
            component: () => import('@/pages/setting/corporation.vue'),
            meta: {
              title: '公司管理'
            }
          },
          {
            path: '/setting/log',
            name: 'log',
            component: () => import('@/pages/setting/log.vue'),
            meta: {
              title: '日志管理'
            }
          },
          {
            path: '/setting/backup',
            name: 'backup',
            component: () => import('@/pages/setting/backup.vue'),
            meta: {
              title: '备份管理'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
const whiteList = ['/login', '/404', '/401', '/403']

router.beforeEach((to) => {
  if (whiteList.indexOf(to.path) !== -1) {
    return true
  } else {
    if (!isEmpty(localStorage.getItem('token'))) {
      return true
    } else {
      return '/login'
    }
  }
})

export default router