import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import ArcoVue from '@arco-design/web-vue'
import '@arco-design/web-vue/dist/arco.css'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import VueFab from 'vue-float-action-button'
import '@arco-themes/vue-coal-stable/index.less'


const app = createApp(App)
app.use(ArcoVueIcon)
app.use(VueFab, {
  iconType: 'iconfont'
})
app.use(router).use(ArcoVue).mount('#app')
